import revive_payload_client_OZYpP2hNsQ from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jXFx7cbLHV from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_l3ZFj9Wbpb from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_eZzFs4uPKI from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@4.21.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import _0_siteConfig_N90MDcOivJ from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.3.9_@vue+compiler-core@3.5.5_nuxt@3.12.4_postcss@8.4._tkbcjz3jwjwl3p6tc4qedvjyfa/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_D8onMejtfI from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8Xeg5KfWO6 from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_bK6a9bLVMS from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WUM3FmxOwY from "/opt/apps/.build/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.21.0_typescript@5.5.4_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/apps/.build/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_1jzLihJhGc from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.21.0_sass@1.69.5_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0tYLGDSLRR from "/opt/apps/.build/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.21.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_ziYcKwnJdq from "/opt/apps/.build/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_wi1LOaeDKI from "/opt/apps/.build/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.0_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EqAU5MkKHl from "/opt/apps/.build/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.0_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import container_client_nVWA1Y6TnX from "/opt/apps/.build/packages/nuxt/app/plugins/container.client.ts";
import splide_b8AOuWtdaN from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/splide.ts";
import i18n_9rrtpUksQb from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/i18n.ts";
import directives_ZJvIqFhvRp from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@4.21.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import nuxt_plugin_tkEAXidp14 from "/opt/apps/.build/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@4.21.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_huiQ9H67Ue from "/opt/apps/.build/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@4.21.0/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_EGEAATfa8x from "/opt/apps/.build/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.4_rollup@4.21.0_vue@3.5.5/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_t8fHaN0ZMF from "/opt/apps/.build/node_modules/.pnpm/nuxt-gtag@1.2.1_rollup@4.21.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_eEtgriJBbM from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.3.9_@vue+compiler-core@3.5.5_nuxt@3.12.4_postcss@8.4._tkbcjz3jwjwl3p6tc4qedvjyfa/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import realtime_e9I2iuf1QN from "/opt/apps/.build/packages/realtime/frameworks/nuxt/plugins/realtime.ts";
import vue_final_modal_Qge1y8PDAA from "/opt/apps/.build/packages/mosaic-ui/plugins/vue-final-modal.ts";
import chat_client_QnolXMNF5e from "/opt/apps/.build/packages/nuxt/layers/chat/plugins/chat.client.ts";
import cabinet_employee_client_rDBJOTl467 from "/opt/apps/.build/packages/nuxt/layers/employee/plugins/cabinet-employee.client.ts";
import auth_client_ja3bg81aM7 from "/opt/apps/.build/packages/nuxt/layers/auth/plugins/auth.client.ts";
import eventbus_4JMxxGDHKy from "/opt/apps/.build/packages/nuxt/app/plugins/eventbus.ts";
import chartjs_client_dSP4DDlQKI from "/opt/apps/.build/packages/nuxt/app/plugins/chartjs.client.ts";
import tippy_Tm6mNIN2oH from "/opt/apps/.build/packages/nuxt/app/plugins/tippy.ts";
import vue_sticky_2bKXDstOpf from "/opt/apps/.build/packages/nuxt/app/plugins/vue-sticky.ts";
import vue_loading_overlay_client_VSHfrAParA from "/opt/apps/.build/packages/nuxt/app/plugins/vue-loading-overlay.client.ts";
import vue_toastification_client_maBAOfUNg5 from "/opt/apps/.build/packages/nuxt/app/plugins/vue-toastification.client.ts";
import fingerprint_client_e9LjqsM7zD from "/opt/apps/.build/packages/nuxt/app/plugins/fingerprint.client.ts";
import persistedstate_client_bEMpAXRUa9 from "/opt/apps/.build/packages/nuxt/app/plugins/persistedstate.client.ts";
import container_G9eXPTg2zh from "/opt/apps/.build/apps/web/app/plugins/container.ts";
import hotjar_client_Cy6i7zwihw from "/opt/apps/.build/apps/web/app/plugins/hotjar.client.ts";
import seo_vqPCsNMqnx from "/opt/apps/.build/apps/web/layers/seo/plugins/seo.ts";
export default [
  revive_payload_client_OZYpP2hNsQ,
  unhead_jXFx7cbLHV,
  router_l3ZFj9Wbpb,
  posthog_client_eZzFs4uPKI,
  _0_siteConfig_N90MDcOivJ,
  payload_client_D8onMejtfI,
  navigation_repaint_client_8Xeg5KfWO6,
  chunk_reload_client_bK6a9bLVMS,
  plugin_vue3_WUM3FmxOwY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1jzLihJhGc,
  plugin_0tYLGDSLRR,
  plugin_ziYcKwnJdq,
  switch_locale_path_ssr_wi1LOaeDKI,
  i18n_EqAU5MkKHl,
  container_client_nVWA1Y6TnX,
  splide_b8AOuWtdaN,
  i18n_9rrtpUksQb,
  directives_ZJvIqFhvRp,
  nuxt_plugin_tkEAXidp14,
  plugin_huiQ9H67Ue,
  plugin_EGEAATfa8x,
  plugin_client_t8fHaN0ZMF,
  i18n_eEtgriJBbM,
  realtime_e9I2iuf1QN,
  vue_final_modal_Qge1y8PDAA,
  chat_client_QnolXMNF5e,
  cabinet_employee_client_rDBJOTl467,
  auth_client_ja3bg81aM7,
  eventbus_4JMxxGDHKy,
  chartjs_client_dSP4DDlQKI,
  tippy_Tm6mNIN2oH,
  vue_sticky_2bKXDstOpf,
  vue_loading_overlay_client_VSHfrAParA,
  vue_toastification_client_maBAOfUNg5,
  fingerprint_client_e9LjqsM7zD,
  persistedstate_client_bEMpAXRUa9,
  container_G9eXPTg2zh,
  hotjar_client_Cy6i7zwihw,
  seo_vqPCsNMqnx
]